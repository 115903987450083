/*
 * @Description:
 * @Author: Ray
 * @Date: 2022-09-27 10:40:28
 * @LastEditTime: 2023-02-07 15:12:03
 * @LastEditors: Ray
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from 'vant'
import { getCookie } from '@/utils/cookie.js'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: () => import('@/views/home'),
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
  },
  {
    path: '/homeListDetail',
    component: () => import('@/views/listDetail/index.vue'),
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/detail',
    component: () => import('@/views/detail'),
    name: 'detali',
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
  },
  {
    path: '/otherDetail',
    component: () => import('@/views/otherDetail'),
    meta: {
      requiresAuth: true,
      keepAlive: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})
// router 报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {

if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)

return originalPush.call(this, location).catch(err => err)

}

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('Detail_from', JSON.stringify(from.path))
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path.indexOf('otherDetail') !== -1) {
      sessionStorage.setItem('otherDetail_referrer', JSON.stringify(to))
    }
    // /homeListDetail
    if (to.path.indexOf('homeListDetail') !== -1) {
      sessionStorage.setItem('homeListDetail_referrer', JSON.stringify(to))
    }
    if (!getCookie('token') || getCookie('token') === null) {
      sessionStorage.setItem('referrer', to.path) //储存跳转路由
      if (history.length >= 1) {
        Toast(`授权登陆中。。。`)
      }
      next({
        path: '/login', // 验证失败要跳转的页面
      })
      return
    } else {
      sessionStorage.setItem('referrer', to.path) //储存来源路由
      // if (from.path.indexOf('otherDetail') !== -1) {
      //   let url = JSON.parse(sessionStorage.getItem('otherDetail_referrer'))
      //     .query.frompath
      //   window.location.href = url
      // }
      next()
      return
    }
  } else {
    next()
  }
})
export default router
