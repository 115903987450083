/*
 * @Description:
 * @Author: Ray
 * @Date: 2022-09-27 10:40:28
 * @LastEditTime: 2023-08-08 14:18:42
 * @LastEditors: Ray
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Search,
  Col,
  Dialog,
  Row,
  DropdownMenu,
  DropdownItem,
  List,
  Cell,
  Icon,
  Checkbox,
  Progress,
  DatetimePicker,
  Popup,
  Picker,
  Toast,
  Loading,
  TreeSelect,
  Tag,
  Tab,
  Tabs,
  Overlay
} from 'vant'
Vue.use(Col)
  .use(Tab)
  .use(Tabs)
  .use(Row)
  .use(Tag)
  .use(List)
  .use(Cell)
  .use(Icon)
  .use(Checkbox)
  .use(Progress)
  .use(DatetimePicker)
  .use(Popup)
  .use(Overlay)
Vue.use(Search).use(Picker).use(Toast).use(Loading).use(Dialog)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(TreeSelect);

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app')

// Vue.directive('focus', {
//   // 当被绑定的元素插入到 DOM 中时……
//   inserted: function (el) {
//     // 聚焦元素
//     // debugger;
//     console.log(el);
//     el.focus()
//   }
// })
