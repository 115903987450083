/*
 * @Description:
 * @Author: Ray
 * @Date: 2022-09-29 09:16:59
 * @LastEditTime: 2022-10-19 11:07:11
 * @LastEditors: Ray
 */

export default {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
}
