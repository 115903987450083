<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2022-09-29 09:17:51
 * @LastEditTime: 2023-04-10 19:19:27
 * @LastEditors: Ray
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookie.js'
import watermark from './utils/waterMark.js'
export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {},
  create() {},
  mounted() {
    if (getCookie('isid') && getCookie('isid') != 'undefined') {
      watermark.set(getCookie('isid'))
    }
  },
}
</script>

<style lang="less">
#app {
  background: #f2f3f7;
  min-height: 100vh;

  // -webkit-overflow-scrolling: touch;

  -webkit-overflow-scrolling: auto;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #004495 !important;
}
.van-list__loading, .van-list__finished-text, .van-list__error-text {
  line-height: 20px;
}
</style>
